import moment from 'moment';

import * as c from '../constants/actions';

import createApiAction from '../api/createApiAction';
import { enqueueSnackbar } from './snackbar';
import { AgentLocation, FamilyDetailsType, IPhoto, IUserProvider, PersonDetailsType } from 'types';
import store from 'store';

// current requested time by PM is 5 minutes
const ONLINE_INDICATOR_IN_SECONDS = 5 * 60;

// util function
/**
 * if agent.online: true, initiate a timer with the difference between ```ONLINE_INDICATOR_IN_SECONDS``` and the time since last online
 *
 * if timer expires set agent.online to false
 *
 * reset the timer if the agent sends a new location update
 */
export const handleOnlineTimeout = (agentId: number, timeISO: string) => {
  // this should be called only when the agent is online
  // don't modify this unless you really understand how it works

  const timeoutIdFromStore = store.getState().family.agentsLocation[agentId]?.timeoutId;

  if (timeoutIdFromStore) {
    // clear previous timeout if exists
    clearTimeout(timeoutIdFromStore);
  }

  const secondsPassedSinceLastUpdate = moment().diff(moment(timeISO), 'seconds');
  const timeoutId = setTimeout(() => {
    store.dispatch(updateSingleAgentCoords({ timeoutId: null, agentId: agentId, timeISO, online: false }))
  }, (ONLINE_INDICATOR_IN_SECONDS - secondsPassedSinceLastUpdate) * 1000)

  return timeoutId;
}

export const getUsersRequest = (params: {
  offset: number;
  role: 'agent';
  page?: number;
  fullName?: string;
  phone?: string;
  userId?: string;
}) =>
  createApiAction({
    method: 'getUsers',
    params,
    requestAction: c.GET_FAMILY_LIST_REQUEST,
    successAction: c.GET_FAMILY_LIST_SUCCESS,
    errorAction: c.GET_FAMILY_LIST_ERROR,
    transformResponse: ({ items }: { items: FamilyDetailsType[] }) => items,
  });

export const uploadToStorage = (params: { photo: IPhoto & File, id: number, otc: string }, cb: (val: any) => void) =>
  createApiAction({
    method: 'uploadToStorage',
    params,
    requestAction: c.UPLOAD_TO_STORAGE_REQUEST,
    successAction: c.UPLOAD_TO_STORAGE_SUCCESS,
    errorAction: c.UPLOAD_TO_STORAGE_ERROR,
    errorHandler(dispatch: any, { response }: { response: { data: { message: string } } }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.UPLOAD_TO_STORAGE_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: ({ data }: { data: any}) => ({ ...params, ...data }),
    afterSuccess: (dispatch: any, data: any) => {
      cb(data);
    },
  });

export const uploadAgentPhoto = (params: {
  userId: number,
  providerId: number,
  photo: IPhoto & File,
}, cb: (val: any) => void) =>
  createApiAction({
    method: 'uploadAgentPhotoNew',
    params,
    requestAction: c.UPLOAD_AGENT_PHOTO_REQUEST,
    successAction: c.UPLOAD_AGENT_PHOTO_SUCCESS,
    errorAction: c.UPLOAD_AGENT_PHOTO_ERROR,
    errorHandler(dispatch: any, { response }: { response: { data: { message: string } } }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.UPLOAD_AGENT_PHOTO_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: (data: any) => {
      return { ...params, ...data }
    },
    afterSuccess: (dispatch: any, data: any) => {
      dispatch(uploadToStorage({ photo: params.photo, id: data.id, otc: data.otc }, (cbData) => {
        cb(cbData);
      }));
    },
  });

export const getAgentLocations = (params: { offset: number, providerIds: Array<number>}) =>
  createApiAction({
    method: 'getAgents',
    params,
    requestAction: c.GET_AGENT_LOCATIONS_REQUEST,
    successAction: c.GET_AGENT_LOCATIONS_SUCCESS,
    errorAction: c.GET_AGENT_LOCATIONS_ERROR,
    transformResponse: (data: {items: FamilyDetailsType[]} ) => {
      const agentCoords = {};
      data.items.forEach((agent) => {
        if (agent.lastLocation) {
          const [lat, lon] = agent.lastLocation.split(',');

          const secondsPassedSinceLastUpdate = moment().diff(moment(agent.locationUpdatedAt), 'seconds');
          const isOnline = secondsPassedSinceLastUpdate < ONLINE_INDICATOR_IN_SECONDS;

          const timeoutId = isOnline && agent.locationUpdatedAt ? handleOnlineTimeout(agent.id, agent.locationUpdatedAt) : null;
          const coordObj: Record<string, AgentLocation> = {
            [agent.id]: {
              timeISO: agent.locationUpdatedAt,
              agentId: agent.id,
              to: 'operator',
              type: 'agent',
              online: isOnline,
              timeoutId,
              lat,
              lon
            }
          };
          Object.assign(agentCoords, coordObj);
        }
      })
      return agentCoords;
    },
  });

export const editAgentRequest = ({ params, cb = () => {} }: {
  params: {
    userId: number,
    providerId: number,
    role: string,
    photoId?: number,
    phone?: string,
    isVideo?: boolean,
  } & Partial<PersonDetailsType['info']>,
  cb?: () => void
}) =>
  createApiAction({
    method: 'editAgent',
    params,
    requestAction: c.EDIT_FAMILY_REQUEST,
    successAction: c.EDIT_FAMILY_SUCCESS,
    errorHandler(dispatch: any, { response }: { response: { data: { message: string } }}) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.EDIT_FAMILY_ERROR,
        payload: response.data.message,
      });
    },
    afterSuccess: () => {
      cb();
    },
  });

export const createAgentRequest = ({ params, cb }: {
  params: {
    phone: string;
    firstName: string;
    lastName: string;
    midName?: string;
    weight?: number;
    height?: number;
    birthdate?: string;
    photoId?: number;
    photo: any;
    role: string;
    providerId: number;
  },
  cb: (val?: any) => void
}) =>
  createApiAction({
    method: 'createAgent',
    params: {
      ...params,
    },
    requestAction: c.ADD_FAMILY_REQUEST,
    successAction: c.ADD_FAMILY_SUCCESS,
    errorAction: c.ADD_FAMILY_ERROR,
    errorHandler(dispatch: any, { response }: { response: { data: { message: string } } }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.ADD_FAMILY_ERROR,
        payload: response.data.message,
      });
    },
    afterSuccess: (dispatch: any, data: { id: number }) => {
      const photoData = {
        userId: data.id,
        providerId: params.providerId,
        photo: params.photo,
      };


      if (params.photo) {
        const editAgent = (photo: any) => {
          dispatch(editAgentRequest({
            params: {
              ...params,
              userId: data.id,
              providerId: params.providerId,
              photoId: photo.id
            },
            cb
          }));
        };
        dispatch(uploadAgentPhoto(photoData, editAgent));
      } else {
        cb();
      }
    },
  });

export const deleteAgentRequest = (params: {
  userId: number,
  providerId: number,
}, cb = () => {}) =>
  createApiAction({
    method: 'deleteAgent',
    params,
    requestAction: c.DELETE_FAMILY_REQUEST,
    successAction: c.DELETE_FAMILY_SUCCESS,
    errorAction: c.DELETE_FAMILY_ERROR,
    errorHandler(dispatch: any, { response } : { response: { data: { message: string } } }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.DELETE_FAMILY_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: () => params.userId,
    afterSuccess: () => {
      cb();
    },
  });

export const updateSingleAgentCoords = (payload: AgentLocation) => {

  // if called from socket, then online === true, timeoutId is undefined
  // if called from end of handleOnlineTimeout, then online === false

  if (payload.online && payload.timeISO) {
    const timeoutId = handleOnlineTimeout(payload.agentId, payload.timeISO);

    return {
      type: c.UPDATE_AGENT_COORDS,
      payload: {
        ...payload,
        timeoutId
      },
    };
  } else {

    // this theoretically should occur ONLY when updateSingleAgentCoords is dispatched at the end of the timeout
    return {
      type: c.UPDATE_AGENT_COORDS,
      payload: payload,
    }

  }
};

export const getAvailableAgentsRequest = () =>
  createApiAction({
    method: 'getAvailableAgents',
    requestAction: c.GET_AVAILABLE_AGENTS_REQUEST,
    successAction: c.GET_AVAILABLE_AGENTS_SUCCESS,
    errorAction: c.GET_AVAILABLE_AGENTS_ERROR,
    transformResponse: (items: IUserProvider[]) => {
      if (items.length === 0) {
        return [];
      }
      return items.map(({ userId }) => userId)
    },
  });

export const setAgentAvailable = (payload: number) => ({
  type: c.SET_AGENT_AVAILABLE,
  payload,
});
export const setAgentBusy = (payload: number) => ({
  type: c.SET_AGENT_BUSY,
  payload,
});