import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TopAppBar, SidebarMenu, WebSocketClient, Loader } from '../components';
import { drawerWidth } from '../constants';
import { IAppState } from '../types';
import { getUsersRequest, getAvailableAgentsRequest } from 'actions/agent';
import { getActivePanics } from '../actions/panic';
import { useHistory } from 'react-router-dom';
import PanicFullscreenModal from 'containers/map-page/panic-fullscreen-modal';

const useStyles = makeStyles((theme: Theme) => ({
  rootStyle: {
    display: 'flex',
  },
  paper: {
    width: drawerWidth,
  },
  contentStyle: {
    flexGrow: 1,
    minHeight: `calc(100vh - 65px)`,
    marginTop: theme.spacing(8),
    padding: '30px 40px',
    position: 'relative',
    maxWidth: '100%',
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 16px',
      maxWidth: '100%',
    },
  },
}));

interface OwnProps {
  children: JSX.Element;
}

interface StateProps {
  role: 'client' | 'operator' | 'admin' | '';
  isLoading?: boolean;
}

type Props = OwnProps & StateProps;

const MainLayout = ({ children, role, isLoading }: Props) => {
  const { rootStyle, paper, contentStyle } = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, toggleOpen] = useState(false);
  const { location: { pathname } } = useHistory();

  useEffect(() => {
    dispatch(getAvailableAgentsRequest());
    if (role !== 'operator') {
      return () => {};
    }
    if (pathname !== '/map') {
      dispatch(getActivePanics({ offset: 50, page: 1 }));
    }
    if (pathname !== '/agents') {
      // in the future this needs to be updated to get agents that where last online for the past 7 days (or 5 minutes)
      dispatch(getUsersRequest({ offset: 10000, role: 'agent' }));
    }

  }, []);


  const handleDrawerToggle = () => {
    toggleOpen(!mobileOpen);
  };

  return (
    <div className={rootStyle}>
      <Loader isLoading={isLoading} />
      <TopAppBar handleDrawerToggle={() => handleDrawerToggle()} />
      <nav>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer variant="temporary" open={mobileOpen} onClose={() => handleDrawerToggle()} classes={{ paper }} keepMounted>
          <SidebarMenu toggleMenuDisplay={() => handleDrawerToggle()} role={role} />
        </Drawer>
      </nav>
      <main className={contentStyle}>
        {children}
        {/* TODO: need to split the logic so the rerenders caused by websocketClient doesnt bleed into other components */}
        <WebSocketClient />
        <PanicFullscreenModal />
      </main>
    </div>
  );
};

const mapStateToProps = ({ auth }: IAppState): StateProps => ({
  role: auth.data.role,
  isLoading:
    auth.editLoading
    || auth.isLoading
});

export default connect(mapStateToProps)(MainLayout);
