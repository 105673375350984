import { LocaleVariants } from 'types';

export const APP_NAME = 'Vyzov24';
export const APP_NAME_LOW = 'vyzov24';

export const isProd = process.env.NODE_ENV === 'production';

export const LS_TOKEN_KEY = `__jwt${APP_NAME}Token__`;

export const INTL_KEY = `__${APP_NAME_LOW}-intl__`;

export const CRM_TOKEN = '9e7161b6-3cf0-4eea-bec6-cce98ee317be';

export const drawerWidth = 200;

export const roleHomepage = {
  operator: '/map',
} as { [key: string]: string };

export const langOptions: LocaleVariants[] = ['ru', 'en'];
export const defaultLang = 'ru';


export const CURRENT_USER_LOCALSTORAGE_KEY = `__${APP_NAME_LOW}__currentUser`;

export const QUERY_PARAMS_KEY = `${APP_NAME_LOW}_queryParams`;
