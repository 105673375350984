import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

export const requiredString = Yup.string()
  .trim()
  .nullable()
  .required(() => <FormattedMessage id="validation.required" defaultMessage="Required" />);

export const requiredFile = Yup.mixed()
  .nullable()
  .test(
    'required',
    () => <FormattedMessage id="validation.required" defaultMessage="Required" />,
    (value) => {
      if (typeof value === 'string') return true;
      if (value instanceof Object) return true;
      return false;
    }
  );

export const validatePassword = Yup.string()
  .trim()
  .nullable()
  .required(() => <FormattedMessage id="validation.required" defaultMessage="Required" />)
  .min(5, () => (
    <FormattedMessage id="validation.minLength" defaultMessage="Minimum length is {min}" values={{ min: 5 }} />
  ));

export const validateBoolean = Yup.string().nullable();

export const requiredEmail = Yup.string()
  .trim()
  .nullable()
  .required(() => <FormattedMessage id="validation.required" defaultMessage="Required" />)
  .email(() => <FormattedMessage id="validation.email" defaultMessage="Invalid Email" />);

export const validatePhone = Yup.string()
  .trim()
  .nullable()
  .required(() => <FormattedMessage id="validation.required" defaultMessage="Required" />);

export const validatePhoneNonRequired = Yup.string()
  .trim()
  .optional()
  .nullable();

export const rulesValidation = Yup.boolean().oneOf([true], () => (
  <FormattedMessage id="registration-mobile.rules-error" defaultMessage="Must Accept Terms and Conditions" />
));

const agentEditSchema = {
  phone: validatePhone,
  firstName: requiredString,
  midName: Yup.string().trim(),
  lastName: requiredString,
  height: Yup.number()
    .nullable()
    .optional()
    .min(1, () => <FormattedMessage id="validation.minNumber" defaultMessage="Minimum is {min}" values={{ min: 1 }} />),
  weight: Yup.number()
    .nullable()
    .optional()
    .min(1, () => <FormattedMessage id="validation.minNumber" defaultMessage="Minimum is {min}" values={{ min: 1 }}/>),
  // photoId: requiredFile,
}

export const editAgentValidations = Yup.object().shape(agentEditSchema);

export const addAgentValidations = Yup.object().shape({
  ...agentEditSchema,
  providerId: Yup.number().required(() => <FormattedMessage id="validation.required" defaultMessage="Required" />),
});
