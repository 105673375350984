const formatPhone = (val: string): string => val.replace(/(\s|\+)/g, '');

export const formatPhoneNumber = (phone: string | undefined) => {
  if (!phone) {
    return '';
  }
  return phone
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})/)
    ?.slice(1)
    ?.join(' ');
};
export default formatPhone;
