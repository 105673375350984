import React, { useState } from 'react';
import { Button, Dialog, Box, Typography, IconButton } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import { darkPaper, gray } from 'constants/colors';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface ModalProps {
  isOpen: boolean;
  toggleModal: Function;
}

export const Modal = ({ isOpen, toggleModal }: ModalProps) => {
  const { container, title, cancelBtn, acceptBtn, href, info, tel } = useStyles();
  return (
    <Dialog open={isOpen} onClose={() => toggleModal()} fullWidth>
      <Box className={container}>
        <Typography variant="h4" className={title}>
          <FormattedMessage id="supportModal.title" />
        </Typography>
        <Typography variant="body2" className={info}>
          <FormattedMessage id="supportModal.info" />
        </Typography>
        <Box display="flex" flexDirection="column" marginBottom="45px">
          <Box className={tel}>
            <FormattedMessage id="common.phone" />
          </Box>
          <Box display="flex" fontWeight="bold">
            <Box color={gray}>+7</Box>
            <Box color={darkPaper}>&nbsp;495 792-22-88</Box>
          </Box>
        </Box>
        <Box display="flex" marginTop="12px">
          <Button size="small" className={cancelBtn} onClick={() => toggleModal()}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <a href="tel:+74957922288" className={href}>
            <Button size="small" className={acceptBtn}>
              <FormattedMessage id="supportModal.button" />
            </Button>
          </a>
        </Box>
      </Box>
    </Dialog>
  );
};

const SupportModal = () => {
  const [isOpen, handleIsOpen] = useState(false);


  const toggleModal = () => {
    handleIsOpen((state) => !state);
  };

  return (
    <>
      <IconButton onClick={toggleModal} >
        <HelpOutlineIcon />
      </IconButton>
      <Modal isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
};

export default SupportModal;
